import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../csspage/banner.css';

const CookieBanner = () => {
    const [isVisible, setIsVisible] = useState(true);

    const handleAccept = () => {
        setIsVisible(false);

    };

    if (!isVisible) {
        return null;
    }

    return (
        <div className="cookie-banner">
            <p>Estimado usuario, utilizamos cookies para mejorar su experiencia en nuestro sitio web y obtener datos para fines analíticos. Al continuar utilizando este sitio, acepta nuestro uso de cookies de acuerdo con nuestra política de cookies.
               Para más información sobre cómo protegemos y utilizamos sus datos, por favor lea nuestro <Link to="/privacy" className="privacy-link">Aviso de Privacidad y Protección de Datos.</Link></p>
            <button onClick={handleAccept}>Aceptar</button>
        </div>
    );
};

export default CookieBanner;
