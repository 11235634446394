import './App.css';
import { Link } from 'react-router-dom';

function Privacy() {
  return (
    <div className="oz-body-wrap">
		<header className="default-header generic-header">
			<div className="container-fluid">
				<div className="header-wrap">
					<div className="header-top d-flex justify-content-between align-items-center">
						<div className="logo">
							<Link to="/"><img src="img/logo.png" alt="" style={{ width: "150px" }}/></Link>
						</div>
						<div className="main-menubar d-flex align-items-center">
							<nav className="hide">
								<Link to="/">Inicio</Link>
							</nav>
							<div className="menu-bar"><span className="lnr lnr-menu"></span></div>
						</div>
					</div>
				</div>
			</div>
		</header>
		<section className="generic-banner elements-banner relative">
			<div className="container">
				<div className="row height align-items-center justify-content-center">
					<div className="col-lg-10">
						<div className="banner-content text-center">
							<h2>Aviso de Privacidad</h2>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section className="sample-text-area">
			<div className="container">
				<h3 className="text-heading">Aviso de privacidad y protección de datos</h3>

                <h4>Finalidad de este sitio web</h4>
                <p className="sample-text">Este sitio web tiene como finalidad realizar estudios de mercado de los usuarios. Seguros Sadek-Pares se dedica a conocer las necesidades y preferencias de nuestros posibles clientes para ofrecer los mejores servicios de seguros de vida. La información que se obtiene en este sitio web se utilizará para mejorar nuestras ofertas y atender mejor a nuestros clientes.</p>

                <h4>Protección de datos y privacidad</h4>
                <p className="sample-text">Seguros Sadek-Pares se compromete a proteger sus datos personales. Esta sección explica cómo obtenemos, utilizamos y resguardamos su información de acuerdo con la normatividad aplicable:</p>
                <ul>
                    <li className="sample-text"><b>Obtención de datos:</b> Obtenemos datos personales que usted nos proporciona voluntariamente a través de nuestro sitio web, como su nombre, correo electrónico y otros datos de contacto.</li>
                    <li className="sample-text"><b>Uso de los datos:</b> Los datos obtenidos se utilizan exclusivamente para fines de investigación de mercado y para mejorar nuestros servicios de seguros de vida. No vendemos, rentamos ni compartimos su información personal con terceros sin su consentimiento, salvo en los casos en que lo requiera la ley.</li>
                    <li className="sample-text"><b>Seguridad de los datos:</b> Implementamos medidas técnicas y administrativas adecuadas para proteger sus datos personales contra el acceso no autorizado, la divulgación, la modificación o la destrucción.</li>
                    <li className="sample-text"><b>Retención de datos:</b> Sus datos personales se conservarán solamente durante el tiempo necesario para cumplir con los fines para los que fueron obtenidos o según lo requiera la ley.</li>
                    <li className="sample-text"><b>Sus derechos:</b> Usted tiene derecho a acceder, rectificar o cancelar sus datos personales, así como a oponerse a su tratamiento. También tiene derecho a la portabilidad de los datos. Para ejercer estos derechos, por favor contáctenos en soporte@keyprediction.com.</li>
                    <li className="sample-text"><b>Cumplimiento de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (LFPDPPP):</b> Si usted es residente en México, tiene ciertos derechos de protección de datos en virtud de la LFPDPPP. Para obtener más información o ejercer sus derechos, póngase en contacto con nosotros en soporte@keyprediction.com.</li>
                    <li className="sample-text"><b>Cumplimiento del RGPD:</b> Si usted se encuentra en el Espacio Económico Europeo (EEE), tiene determinados derechos de protección de datos en virtud del Reglamento General de Protección de Datos (RGPD). Para obtener más información o ejercer sus derechos, póngase en contacto con nosotros en soporte@keyprediction.com.</li>
                </ul>

                <h4>Cookies</h4>
                <p className="sample-text">Nuestro sitio web utiliza cookies para mejorar su experiencia de navegación y obtener datos para fines analíticos. Al utilizar nuestro sitio web, usted acepta nuestro uso de cookies de acuerdo con nuestra política de cookies.</p>

                <h4>¿Qué son las cookies?</h4>
                <p className="sample-text">Las cookies son pequeños archivos de texto que se almacenan en su dispositivo cuando visita un sitio web. Nos ayudan a comprender cómo interactúa con nuestro sitio y a mejorar su experiencia.</p>

                <h4>Tipos de cookies que utilizamos</h4>
                <ul>
                    <li className="sample-text"><b>Cookies esenciales:</b> Estas cookies son necesarias para que el sitio web funcione correctamente. Permiten funcionalidades básicas como seguridad, gestión de red y accesibilidad.</li>
                    <li className="sample-text"><b>Cookies funcionales:</b> Estas cookies permiten que el sitio web recuerde las elecciones que usted realiza y proporcione características mejoradas y más personalizadas.</li>
                    <li className="sample-text"><b>Cookies de orientación:</b> Estas cookies se utilizan para mostrar anuncios que sean relevantes para usted y sus intereses. También ayudan a medir la eficacia de las campañas publicitarias.</li>
                </ul>

                <h4>Gestión de cookies</h4>
                <p className="sample-text">Usted puede controlar y gestionar las cookies a través de la configuración de su navegador. La mayoría de los navegadores le permiten rechazar o aceptar cookies. Tenga en cuenta que deshabilitar las cookies puede afectar la funcionalidad de nuestro sitio web.</p>

                <h4>Servicios de terceros</h4>
                <p className="sample-text">Podemos utilizar servicios de terceros para ayudar con la investigación de mercado y el análisis de datos. Estos terceros están sujetos a acuerdos de protección de datos y tienen prohibido utilizar sus datos personales para cualquier propósito que no sea prestarnos servicios.</p>

                <h4>Cambios a este aviso</h4>
                <p className="sample-text">Es posible que actualicemos este aviso de privacidad y protección de datos de vez en cuando para reflejar cambios en nuestras prácticas o por otras razones operativas, legales o regulatorias. Le recomendamos que revise esta página periódicamente para mantenerse informado sobre cómo protegemos su información. Si tiene alguna pregunta o inquietud sobre este aviso o nuestras prácticas de protección de datos, comuníquese con nosotros en soporte@keyprediction.com.</p>
			</div>
		</section>
		<section className="contact-area pt-100 pb-100 relative">
			<div className="overlay overlay-bg"></div>
			<div className="container">
				<div className="row justify-content-center text-center">
					<div className="single-contact col-lg-6 col-md-8">
						<h2 className="text-white">Send Us <span>Message</span></h2>
						<p className="text-white">
							Most people who work in an office environment, buy computer products.
						</p>
					</div>
				</div>
				<form id="myForm" action="mail.php" method="post" className="contact-form">
					<div className="row justify-content-center">
						<div className="col-lg-5">
							<input name="fname" placeholder="Enter your name" onFocus="this.placeholder = ''" onBlur="this.placeholder = 'Enter your name'" className="common-input mt-20" required="" type="text" />
						</div>
						<div className="col-lg-5">
							<input name="email" placeholder="Enter email address" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$" onFocus="this.placeholder = ''" onBlur="this.placeholder = 'Enter email address'" className="common-input mt-20" required="" type="email" />
						</div>
						<div className="col-lg-10">
							<textarea className="common-textarea mt-20" name="message" placeholder="Messege" onFocus="this.placeholder = ''" onBlur="this.placeholder = 'Messege'" required=""></textarea>
					</div>
					<div className="col-lg-10 d-flex justify-content-end">
						<button className="primary-btn white-bg d-inline-flex align-items-center mt-20"><span className="mr-10">Send Message</span><span className="lnr lnr-arrow-right"></span></button> <br />
					</div>
					<div className="alert-msg"></div>
					</div>
				</form>
			</div>
		</section>
		<footer className="section-gap">
			<div className="container">
				<div className="row pt-60">
					<div className="col-lg-3 col-sm-6">
						<div className="single-footer-widget">
							<h6 className="text-uppercase mb-20">Top Product</h6>
							<ul className="footer-nav">
								<li><a href="/">Managed Website</a></li>
								<li><a href="/">Manage Reputation</a></li>
								<li><a href="/">Power Tools</a></li>
								<li><a href="/">Marketing Service</a></li>
							</ul>
						</div>
					</div>
					<div className="col-lg-3 col-sm-6">
						<div className="single-footer-widget">
							<h6 className="text-uppercase mb-20">Navigation</h6>
							<ul className="footer-nav">
								<li><a href="/">Home</a></li>
								<li><a href="/">Main Features</a></li>
								<li><a href="/">Offered Services</a></li>
								<li><a href="/">Latest Portfolio</a></li>
							</ul>
						</div>
					</div>
					<div className="col-lg-3 col-sm-6">
						<div className="single-footer-widget">
							<h6 className="text-uppercase mb-20">Compare</h6>
							<ul className="footer-nav">
								<li><a href="/">Works & Builders</a></li>
								<li><a href="/">Works & Wordpress</a></li>
								<li><a href="/">Works & Templates</a></li>
							</ul>
						</div>
					</div>

					<div className="col-lg-3 col-sm-6">
						<div className="single-footer-widget">
							<h6 className="text-uppercase mb-20">Quick About</h6>
							<p>
								Lorem ipsum dolor sit amet, consecteturadipisicin gelit, sed do eiusmod tempor incididunt.
							</p>
							<p>
								+00 012 6325 98 6542 <br />
								support@colorlib.com
							</p>
							<div className="footer-social d-flex align-items-center">
								<a href="/"><i className="fa fa-facebook"></i></a>
								<a href="/"><i className="fa fa-twitter"></i></a>
								<a href="/"><i className="fa fa-dribbble"></i></a>
								<a href="/"><i className="fa fa-behance"></i></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
    </div>
  );
}

export default Privacy;